const scrollToTopBtn = () => {
  let btn = document.querySelector("#btn_to_top");

  window.addEventListener("scroll", function () {
    if (window.pageYOffset > 300) {
      btn.classList.add("show");
    } else {
      btn.classList.remove("show");
    }
  });

  btn.addEventListener("click", function (e) {
    e.preventDefault();
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  });
};

export default scrollToTopBtn;
