import "./modules/slider";
import images from "./modules/images";
import scroll from "./modules/scroll";
import scrollToTopBtn from "./modules/scrollToTopBtn";

function isMobileDevice() {
  const userAgent = navigator.userAgent;
  return /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(
    userAgent
  );
}

window.addEventListener("DOMContentLoaded", () => {
  "use strict";

  images();
  scroll();
  scrollToTopBtn();
  if (isMobileDevice()) {
    const viberLinks = document.querySelectorAll(".viber");
    [...viberLinks].map((link) =>
      link.setAttribute("href", "viber://add?number=380939154740")
    );
  }
});
