const scroll = () => {
  const anchors = document.querySelectorAll('a[href^="#"]');

  for (let anchor of anchors) {
    anchor.addEventListener("click", function (e) {
      e.preventDefault();
      const goto = anchor.hasAttribute("href")
        ? anchor.getAttribute("href")
        : "body";
      const element = document.querySelector(goto);
      const scrollOffset = element.offsetTop - 100;
      window.scrollTo({
        top: scrollOffset,
        behavior: "smooth",
      });
    });
  }
};

export default scroll;
